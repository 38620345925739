import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities/subscriber';
import { OperatorValue as Op, SegmentRule } from '../types';
import { Rule } from '.';

export class TagsRule extends Rule {
  constructor(private rule: SegmentRule<string[] | string[][]>) {
    super();
  }
  /*
  The input of an IN rule such as:
  [
    ['vip', 'guest'],
    ['president']
  ]
  will be converted into the following SQL query:
  (
    (
      EXISTS (SELECT 1 FROM subscriber_tags st WHERE st."subscriberId" = subscriber."id" AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = vip AND "tenantId" = tenantId))
      OR
      EXISTS (SELECT 1 FROM subscriber_tags st WHERE st."subscriberId" = subscriber."id" AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = guest AND "tenantId" = tenantId))
    ) 
    AND
    (
      EXISTS (SELECT 1 FROM subscriber_tags st WHERE st."subscriberId" = subscriber."id" AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = president AND "tenantId" = tenantId))
    )
  )

  The input of a NOT IN rule such as:
  [
    ['vip', 'guest'],
    ['president']
  ]
  will be converted into the following SQL query:
  NOT (
    (
      EXISTS (SELECT 1 FROM subscriber_tags st WHERE st."subscriberId" = subscriber."id" AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = vip AND "tenantId" = tenantId))
      OR
      EXISTS (SELECT 1 FROM subscriber_tags st WHERE st."subscriberId" = subscriber."id" AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = guest AND "tenantId" = tenantId))
    ) 
    AND
    (
      EXISTS (SELECT 1 FROM subscriber_tags st WHERE st."subscriberId" = subscriber."id" AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = president AND "tenantId" = tenantId))
    )
  )

  */
  updateQuery(query: SelectQueryBuilder<Subscriber>, tenantId: string) {
    if (!this.rule.value?.length) return;

    const andConditions: string[] = [];
    const params: Record<string, any> = {};

    this.rule.value.forEach((orGroup) => {
      const orConditions: string[] = [];

      orGroup.forEach((tagName) => {
        const paramKey = this.createParameterHash();
        params[paramKey] = tagName;

        orConditions.push(`EXISTS (
              SELECT 1
              FROM subscriber_tags st
              WHERE st."subscriberId" = subscriber."id"
              AND st."tagId" = (SELECT "id" FROM TAG WHERE "name" = :${paramKey} AND "tenantId" = '${tenantId}')
            )`); // We know that subscriber will already be joined to the main query
      });

      andConditions.push(`(${orConditions.join(' OR ')})`);
    });

    switch (this.rule.operator) {
      case Op.in:
        query.andWhere(`(${andConditions.join(' AND ')})`, params);
        break;
      case Op.notIn:
        query.andWhere(`NOT (${andConditions.join(' AND ')})`, params);
        break;
      default:
        throw new Error(`operator "${this.rule.operator}" not supported`);
    }
  }
}
