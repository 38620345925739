import crypto from 'crypto';
import { SelectQueryBuilder } from 'typeorm';
import { Subscriber } from '@pushologies/database-service/db/entities';

export abstract class Rule {
  abstract updateQuery(query: SelectQueryBuilder<Subscriber>, tenantId?: string): void;

  protected createParameterHash() {
    return crypto.createHash('sha256').update(crypto.randomUUID()).digest('hex');
  }
}
